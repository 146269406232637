/* clears the ‘X’ from Internet Explorer */
input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

/* styled-shared ERROR_TEXT_HEX */
.input-error .MuiInputLabel-root {
    color: #CC0000;
}

/* styled-shared ERROR_BORDER_HEX */
.input-error .MuiOutlinedInput-notchedOutline {
    border-color: #F24167;
}

#globalThemeSwitch span.MuiSwitch-track {
    background-color: #ffffff;
    opacity: 1;
}

#globalThemeSwitch .MuiSwitch-thumb {
    background-color: #45a29e;
}

@-webkit-keyframes waverOpacity {
    0% { opacity: 0.5; }
    50% { opacity: 0.75; }
    100% { opacity: 0.5; }
}

@keyframes waverOpacity {
    0% { opacity: 0.75; }
    50% { opacity: 0.95; }
    100% { opacity: 0.75; }
}

@keyframes appendMessageListAnimation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes pulse {
    0%{
      opacity: 1;
    }
    50% {
      opacity: 0.75;
    }
    100% {
      opacity: 1;
    }
  }

.pulse-animation .MuiBadge-badge {
    -webkit-animation-name: pulse;
    -webkit-animation-duration: 1s;
    animation-name: pulse;
    animation-duration: 1s;
    animation-iteration-count: 5;
}

.append-animate {
    transform-origin: 50% 0;
    animation: appendMessageListAnimation .2s linear;
}

.waver-opacity {
    -webkit-animation-name: waverOpacity;
    -webkit-animation-duration: 2s;
    animation-name: waverOpacity;
    animation-duration: 2s;
    animation-iteration-count: 10;
}

.MuiAutocomplete-groupLabel {
    border: 2px solid #dedede;
}

.sticky-paper-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}
